/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Mar 3, 2015, 11:18:16 AM
    Author     : kbroul
*/

$color-r3d-green:       #94be1d;
$color-r3d-blue:        #289cd3;
$color-r3d-yellow:      #F9CB40;
$color-r3d-orange:      #EE8434;
$color-r3d-red:         #BF211E;
$color-r3d-gray:        #777777;

$color-r3d-lightgray:   #DDDDDD;
$color-r3d-lightgreen:  #b0be89;

.r3d-background-green{
    background-color: $color-r3d-green;
}

.r3d-background-blue{
    background-color: $color-r3d-blue;
}

.r3d-background-yellow{
    background-color: $color-r3d-yellow;
}

.r3d-background-orange{
    background-color: $color-r3d-orange;
}

.r3d-background-red{
    background-color: $color-r3d-red;
}

.r3d-background-gray{
    background-color: $color-r3d-gray;
}