/*
    Created on : Mar 3, 2015, 11:03:34 AM
    Author     : kbroul
*/

@import 'colors';

.r3d-repository-suggest .description h1 {
    font-weight: bold;
    margin-bottom: 1em;
}

.r3d-repository-suggest .description ul {
    margin-top: 1em;
    margin-bottom: 1em;
}

.r3d-repository-suggest .form {
    margin-top: 50px;
}

.r3d-repository-suggest-hint label {
    cursor: help;
    text-decoration: underline dotted;
    text-underline-offset: 3px;
}

