/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Mar 3, 2015, 11:03:34 AM
    Author     : kbroul
*/

@import 'colors';


$color-swagger-darkblue:    #0f6ab4;
$color-swagger-blue:        #c3d9ec;
$color-swagger-lightblue:   #e7f0f7;


.r3d-api-doc{
    margin-top: 30px;
    font-size:  16px;
}

/* @todo styling from the apidoc should be cleaned and move to a own document */
div.r3d-api-doc {   
    @font-face{font-family:'Droid Sans';font-style:normal;font-weight:400;src:local('Droid Sans'),local('DroidSans'),url(//themes.googleusercontent.com/static/fonts/droidsans/v3/s-BiyweUPV0v-yRb-cjciL3hpw3pgy2gAi-Ip7WPMi0.woff) format('woff')}@font-face{font-family:'Droid Sans';font-style:normal;font-weight:700;src:local('Droid Sans Bold'),local('DroidSans-Bold'),url(//themes.googleusercontent.com/static/fonts/droidsans/v3/EFpQQyG9GqCrobXxL-KRMXbFhgvWbfSbdVg11QabG8w.woff) format('woff')}.pln{color:#000}@media screen{.str{color:#080}.kwd{color:#008}.com{color:#800}.typ{color:#606}.lit{color:#066}.pun,.opn,.clo{color:#660}.tag{color:#008}.atn{color:#606}.atv{color:#080}.dec,.var{color:#606}.fun{color:red}}@media print,projection{.str{color:#060}.kwd{color:#006;font-weight:bold}.com{color:#600;font-style:italic}.typ{color:#404;font-weight:bold}.lit{color:#044}.pun,.opn,.clo{color:#440}.tag{color:#006;font-weight:bold}.atn{color:#404}.atv{color:#060}}pre.prettyprint{padding:2px;border:1px solid #888}ol.linenums{margin-top:0;margin-bottom:0}li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8{list-style-type:none}li.L1,li.L3,li.L5,li.L7,li.L9{background:#eee}

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    smany, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }

    body {
      line-height: 1;
      font-family: "Droid Sans", sans-serif;
    }

    ol, ul {
      list-style: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    caption, th, td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
      display: block;
    }

    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
      text-decoration: none;
    }
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
      text-decoration: underline;
    }

    h1 {
      color: black;
      font-size: 1.5em;
      line-height: 1.3em;
      padding: 10px 0 10px 0;
      font-family: "Droid Sans", sans-serif;
      font-weight: bold;
    }

    h2 {
      color: black;
      font-size: 1.3em;
      padding: 10px 0 10px 0;
    }
    h2 a {
      color: black;
    }

    h3 {
      color: black;
      font-size: 1.1em;
      padding: 10px 0 10px 0;
    }

    p {
      line-height: 1.4em;
      padding: 0 0 10px 0;
      color: #333333;
    }

    a {
      color: #547f00;
    }

    strong {
      font-family: "Droid Sans", sans-serif;
      font-weight: bold;
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    code, pre {
      font-family: "Anonymous Pro", "Menlo", "Consolas", "Bitstream Vera Sans Mono", "Courier New", monospace;
      background-color: #fcf6db;
    }

    p code {
      padding: 1px 4px 1px 4px;
      border: 1px solid #e5e0c6;
    }

    pre {
      padding: 10px;
      border: 1px solid #e5e0c6;
      margin-bottom: 15px;
      line-height:1.2em;
    }

    table.fullwidth {
      width: 100%;
    }
    table.fullwidth > tbody > tr {
      border-bottom: 1px solid #cccccc;
    }
    table.fullwidth > tbody tr:last-child {
      border-bottom: none;
    }
    table thead tr th {
      padding: 5px;
      font-size: 0.9em;
      color: #666666;
      border-bottom: 1px solid #999999;
    }
    table tbody tr td {
      padding: 6px;
      font-size: 0.9em;
      vertical-align: top;
      line-height: 1.3em;
    }

    #header {
      background-color: #89BF04;
      padding: 1%;
      margin-bottom: 1%;
    }

    #header h1 {
      font-size: 1.2em;
      float: left;
    }

    #header a {
      text-decoration: none;
    }

    #sandbox_configuration {
        float: right;
        padding: 10px 0 10px 0;
    }

    #colophon {
      margin: 0 15px 40px 15px;
      padding: 10px 0;
      font-size: 0.8em;
      border-top: 1px solid #dddddd;
      font-family: "Droid Sans", sans-serif;
      color: #999999;
      font-style: italic;
    }

    #resources {
      padding: 0 1%;
      font-family: "Droid Sans", sans-serif;
      font-size: 0.9em;
    }

    .section {
        padding: 5px 20px;
        border-bottom: 1px solid #ddd;
    }

    .section h1 {
        padding: 0;
    }

    .section.active {
        border: 1px solid #ddd;
        background: #f8f8f8;
        margin: 15px 0;
    }

    .section.active h1 {
        padding: 10px 0;
    }

    .section .actions {
        text-align: right;
        float: right;
        margin-top: 10px;
    }

    .section .actions a {
        cursor: pointer;
        margin-left: 10px;
    }

    .section .actions a:hover {
        text-decoration: underline;
    }

    li.resource {
      width: 100%;
      margin-bottom: 10px;      
    }

    /* heading */
    .heading {
      border: 1px solid transparent;
      float: none;
      clear: both;
      overflow: hidden;
      display: block;
    }
    .heading h2 {
      color: #999999;
      padding-left: 0;
      display: block;
      clear: none;
      float: left;
      font-family: "Droid Sans", sans-serif;
      font-weight: bold;
    }
    .heading ul.options {
      overflow: hidden;
      padding: 0;
      display: block;
      clear: none;
      float: right;
      margin: 6px 10px 0 0;
    }
    .heading ul.options li {
      float: left;
      clear: none;
      margin: 0;
      padding: 2px 10px;
      border-right: 1px solid #dddddd;
      color: #666666;
      font-size: 0.9em;
    }
    .heading ul.options li:first-child,
    .heading ul.options li.first {
      padding-left: 0;
    }
    .heading ul.options li:last-child,
    .heading ul.options li.last {
      padding-right: 0;
      border-right: none;
    }

    /* operations */
    li.operation {
      float: none;
      clear: both;
      overflow: hidden;
      display: block;
      margin: 0 0 10px;
      padding: 0 0 0 0;
    }
    li.operation .heading {
      margin: 0 0 0 0;
      padding: 0;
      background-color: #f0f0f0;
      border: 1px solid #ddd;
    }
    li.operation .heading h3 {
      display: block;
      clear: none;
      float: left;
      width: auto;
      margin: 0;
      padding: 0;
      line-height: 1.1em;
      color: black;
    }
    li.operation .heading h3 span {
      margin: 0;
      padding: 0;
    }
    li.operation .heading h3 span.icon {
        display: inline-block;
        height: 12px;
        width: 12px;
        margin-left: 3px;
        background: no-repeat center center;
    }
    li.operation .heading h3 span.lock {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUHEisepJ6ljAAAAJ5JREFUGNNt0LEOAUEUheEPuwkFtSg0old4Eo/imbQKiULpBZQSCtFoaIjSktXMxpjsSW5xzvnnZmb4aYMymg9WEq1Decc1zCNkyxisoFGUTXDGEZpR8cIp8jccKiaLigwDdMP9hughr8ptALtYoB18C+Pgd5KXlrhgX5P/mSfmmKVgM/mmDP1qQ1rEyjFFkYKNmtMF3uikYFGzOdXnC5FWMZNd2GfvAAAAAElFTkSuQmCC");

    }
    li.operation .heading h3 span.keys {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAMCAYAAAC0qUeeAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUHEisb1PRRAwAAAN9JREFUKM9lz71KQ0EQhuEnJ7EIKhZWaRULu4htLLwDvYRgZWmTWoI3kAuIlVik0U5Io4USRMFOUGwU7cQ/kAQMajMHDpuBZWd235lvPtjHDT4xxhuu0ZJEhhXU8YAG7rCKBWyn8EnkVSxjOuoPbKTT1/GXnCd0YqWt4uQrk3GLGcxiswgPcRgG4QsDzKMSKtUc/kUbFwEf4BlrUdeCk8WOj3jBO+5xhGOMwmQzh6Ec9zemwtgOLuN9D4tZYqwUSvnuu3jFHLpZASqHUqXQfIZe5PX8Y4RTLKGfqLVwjp9/HR4zOkGnnAoAAAAASUVORK5CYII=");
    }
    li.operation .heading h3 span.http_method i, li.operation .heading h3 span.deprecated i {
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      display: inline-block;
      width: 50px;
      font-size: 0.7em;
      text-align: center;
      padding: 7px 0 4px 0;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      background-color: #ccc;
    }
    li.operation .heading h3 span.deprecated i {
      width: 75px;
      background-color: #F00;
    }
    li.operation .heading h3 span.path {
      padding-left: 5px;
    }

    li.operation .heading h3 span.tag {
      color: #FFFFFF;
      font-size: 0.7em;
      vertical-align: baseline;
      background-color: #d9534f;
      padding-bottom: 3px;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 2px;
      border-radius: 4px;
    }

    li.operation div.content {
      border: 1px solid #ddd;
      padding: 10px;
      -moz-border-radius-bottomleft: 6px;
      -webkit-border-bottom-left-radius: 6px;
      border-bottom-left-radius: 6px;
      -moz-border-radius-bottomright: 6px;
      -webkit-border-bottom-right-radius: 6px;
      border-bottom-right-radius: 6px;
      margin: 0 0 20px 0;
      background-color: #f0f0f0;
    }
    li.operation > div.content {
        border-top:0px;
    }
    li.operation div.content h4 {
      font-size: 1.1em;
      margin: 0;
      padding: 15px 0 5px 0;
    }
    li.operation div.content form input[type='text'].error {
      outline: 2px solid #cc0000;
    }

    /* GET operations */
    li.operation.get .heading {
      border-color: #c3d9ec;
      background-color: #e7f0f7;
    }
    li.operation.get .heading h3 span.http_method i {
      background-color: #0f6ab4;
    }
    li.operation.get .heading ul.options li {
      border-right-color: #c3d9ec;
      color: #0f6ab4;
    }

    li.operation.get div.content {
      background-color: #ebf3f9;
      border-color: #c3d9ec;
    }
    li.operation.get div.content h4 {
      color: #0f6ab4;
    }

    /* POST operations */
    li.operation.post .heading {
      border-color: #a7e1a1;
      background-color: #d4f7cd;
    }
    li.operation.post .heading h3 span.http_method i{
      background-color: #10a54a;
    }
    li.operation.post .heading ul.options li {
      border-right-color: #c3e8d1;
      color: #10a54a;
    }

    li.operation.post div.content {
      background-color: #ebf7f0;
      border-color: #c3e8d1;
    }
    li.operation.post div.content h4 {
      color: #10a54a;
    }

    /* ANY operations */
    li.operation.any .heading {
      background-color: lightgray;
      border-color: gray;
    }
    li.operation.any .heading h3 span.http_method i {
      background-color: #000;
    }
    li.operation.any .heading ul.options li {
      color: #000;
      border-right-color: gray;
    }

    li.operation.any div.content {
      background-color: lightgray;
      border-color: gray;
    }
    li.operation.any div.content h4 {
      color: #000;
    }

    /* PUT operations */
    li.operation.put .heading {
      background-color: #f9f2e9;
      border-color: #f0e0ca;
    }
    li.operation.put .heading h3 span.http_method i {
      background-color: #c5862b;
    }
    li.operation.put .heading ul.options li {
      border-right-color: #f0e0ca;
      color: #c5862b;
    }

    li.operation.put div.content {
      background-color: #faf5ee;
      border-color: #f0e0ca;
    }
    li.operation.put div.content h4 {
      color: #c5862b;
    }

    /* DELETE operations */
    li.operation.delete .heading {
      background-color: #f5e8e8;
      border-color: #e8c6c7;
    }
    li.operation.delete .heading h3 span.http_method i {
      background-color: #a41e22;
    }
    li.operation.delete .heading ul.options li {
      border-right-color: #e8c6c7;
      color: #a41e22;
    }

    li.operation.delete div.content {
      background-color: #f7eded;
      border-color: #e8c6c7;
    }
    li.operation.delete div.content h4 {
      color: #a41e22;
    }

    /* PATCH operations */
    li.operation.patch .heading {
      background-color: #f5e8e8;
      border-color: #e8c6e7;
    }
    li.operation.patch .heading h3 span.http_method i {
      background-color: #a41ee2;
    }
    li.operation.patch .heading ul.options li {
      border-right-color: #e8c6c7;
      color: #a41ee2;
    }

    li.operation.patch div.content {
      background-color: #f7eded;
      border-color: #e8c6c7;
    }
    li.operation.patch div.content h4 {
      color: #a41ee2;
    }

    /* LINK operations */
    li.operation.link .heading {
      background-color: #F7F7D5;
    }
    li.operation.link .heading h3 span.http_method i {
      background-color: #C3D448;
    }
    li.operation.link .heading ul.options li {
      color: #C3D448;
    }

    li.operation.link div.content {
      background-color: #F7F7D5;
    }
    li.operation.link div.content h4 {
      color: #C3D448;
    }

    /* UNLINK operations */
    li.operation.unlink .heading {
      background-color: #FFEBDE;
    }
    li.operation.unlink .heading h3 span.http_method i {
      background-color: #FF8438;
    }
    li.operation.unlink .heading ul.options li {
      color: #FF8438;
    }

    li.operation.unlink div.content {
      background-color: #FFEBDE;
    }
    li.operation.unlink div.content h4 {
      color: #FF8438;
    }

    .toggler {
      cursor: pointer;
    }


    /** sandbox */
    .pane:not(.selected) {
      display: none;
    }

    .result pre {
      border: none;
      font-size: 0.9em;
      overflow: auto;
    }

    .result pre.to-shrink,
    .result pre.to-expand {
      cursor:pointer;
    }

    .result pre.to-expand {
      height: 1em;
      overflow:hidden;
    }

    .tabs li {
      display: inline;
      float: left;
      margin: 10px;
      cursor: pointer;
    }

    .tabs li.selected {
      font-weight: bold;
    }

    .panes {
      clear: both;
    }

    .pane.sandbox {
      border: 1px solid #C3D9EC;
      padding: 10px;
    }

    .pane.sandbox legend {
      padding-bottom: 5px;
    }

    .remove {
      cursor: pointer;
    }

    .pane.sandbox .result {
      display: none;
    }

    form .parameters {
      float: left;
      width: 50%;
    }

    form .parameters .tuple input {
            width: 40%;
    }

    form .headers,
    form .request-content {
      float: left;
      width: 25%;
    }

    .buttons {
      clear: both;
      padding-top: 10px;
    }

    .request-content textarea {
        width:100%;
        min-width:100%;
        max-width:100%;
        height:5em;
        margin-bottom:10px;
    }

    .pane.content div ul {
        list-style-type: disc;
        margin-left: 20px;
        margin-bottom: 10px;
    }

    .motd {
      padding:20px;
    }
}