/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-access_inactive {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px 0px;
  width: 20px;
  height: 20px;
}
.icon-api_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -22px;
  width: 20px;
  height: 20px;
}
.icon-api_inactive {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -44px;
  width: 20px;
  height: 20px;
}
.icon-ark_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -66px;
  width: 20px;
  height: 20px;
}
.icon-certificate_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -88px;
  width: 20px;
  height: 20px;
}
.icon-certificate_inactive {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -110px;
  width: 20px;
  height: 20px;
}
.icon-closed_access_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -132px;
  width: 20px;
  height: 20px;
}
.icon-doi_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -154px;
  width: 20px;
  height: 20px;
}
.icon-hdl_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -176px;
  width: 20px;
  height: 20px;
}
.icon-info_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -198px;
  width: 20px;
  height: 20px;
}
.icon-info_inactive {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -220px;
  width: 20px;
  height: 20px;
}
.icon-licenses_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -242px;
  width: 20px;
  height: 20px;
}
.icon-licenses_inactive {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -264px;
  width: 20px;
  height: 20px;
}
.icon-open_access_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -286px;
  width: 20px;
  height: 20px;
}
.icon-other_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -308px;
  width: 20px;
  height: 20px;
}
.icon-persistent_identifier_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -330px;
  width: 20px;
  height: 20px;
}
.icon-persistent_identifier_inactive {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -352px;
  width: 20px;
  height: 20px;
}
.icon-policy_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -374px;
  width: 20px;
  height: 20px;
}
.icon-policy_inactive {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -396px;
  width: 20px;
  height: 20px;
}
.icon-purl_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -418px;
  width: 20px;
  height: 20px;
}
.icon-restricted_access_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -440px;
  width: 20px;
  height: 20px;
}
.icon-urn_active {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -462px;
  width: 20px;
  height: 20px;
}
.icon-access_inactive_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -484px;
  width: 32px;
  height: 32px;
}
.icon-api_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -518px;
  width: 32px;
  height: 32px;
}
.icon-api_inactive_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -552px;
  width: 32px;
  height: 32px;
}
.icon-ark_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -586px;
  width: 32px;
  height: 32px;
}
.icon-certificate_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -620px;
  width: 32px;
  height: 32px;
}
.icon-certificate_inactive_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -654px;
  width: 32px;
  height: 32px;
}
.icon-closed_access_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -688px;
  width: 32px;
  height: 32px;
}
.icon-doi_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -722px;
  width: 32px;
  height: 32px;
}
.icon-hdl_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -756px;
  width: 32px;
  height: 32px;
}
.icon-info_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -790px;
  width: 32px;
  height: 32px;
}
.icon-info_inactive_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -824px;
  width: 32px;
  height: 32px;
}
.icon-licenses_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -858px;
  width: 32px;
  height: 32px;
}
.icon-licenses_inactive_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -892px;
  width: 32px;
  height: 32px;
}
.icon-open_access_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -926px;
  width: 32px;
  height: 32px;
}
.icon-other_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -960px;
  width: 32px;
  height: 32px;
}
.icon-persistent_identifier_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -994px;
  width: 32px;
  height: 32px;
}
.icon-persistent_identifier_inactive_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -1028px;
  width: 32px;
  height: 32px;
}
.icon-policy_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -1062px;
  width: 32px;
  height: 32px;
}
.icon-policy_inactive_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -1096px;
  width: 32px;
  height: 32px;
}
.icon-purl_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -1130px;
  width: 32px;
  height: 32px;
}
.icon-restricted_access_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -1164px;
  width: 32px;
  height: 32px;
}
.icon-urn_active_32 {
  background-image: url(../../images/icon-sprites.png);
  background-position: 0px -1198px;
  width: 32px;
  height: 32px;
}
