/* @todo: Structure the stuff and probably split it up into multiple single css files according to the
views directory */

@import 'modules/repository';
@import 'modules/suggest';
@import 'modules/api';
@import 'modules/colors';
@import 'modules/icon-sprites';
@import 'modules/breadcrumb';
@import "modules/flags32.both";


@media (min-width: 1200px) {
    .container {
        width: 960px;
    }
}

.r3d-userlist {
    display: inline-block;
    width: 400px;
    margin-bottom: 25px;
    padding: 10px;
    border: 1px dotted lightgray;
}

.r3d-userlist .r3d-userimage {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
}

.r3d-userlist .r3d-usershortinfo {
    display: inline-block;
    vertical-align: middle;
}

.r3d-userlist .r3d-usershortinfo strong{
    font-weight: 900;
}

.r3d-userlist .r3d-useraction {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.r3d-userlist .r3d-useraction a {
    color: #333333;
    font-size: 35px;
}

.r3d-userlist .r3d-useraction a:hover {
    color: #999999;
}

.form_error ul {
    border: 1px solid darkred;
    padding: 5px;
    font-size: smaller;
    background-color: #dFb5b4;
    margin-bottom: 5px;
    list-style-type: none;
    color: darkred;
}

.r3d-repositorylist {
    padding: 3px 0;
}

.r3d-repositorylist a{
    color: #333;
}

.r3d-repositorylist:last-child {
    border-bottom: none;
}

.r3d-repositorylist:nth-child(odd) {
    background-color: $color-r3d-lightgray;
}

.r3d-repositorylist:nth-child(even) {

}

.r3d-qualitycheckbox {
    padding-right: 30px;
}

.r3d-qualitylist {
    padding: 3px 0;
}

.r3d-qualitylist:last-child {
    border-bottom: none;
}

.r3d-qualitylist.odd {
    background-color: $color-r3d-lightgray;
}

.r3d-qualitylist.even {

}

.r3d-qualitylist.r3d-testcase-resolved {
    display: none;
    color: gray;
}

.r3d-qualitylist:hover {
    background-color: lightgrey;
}

.r3d-qualityline {
    margin: 0;
    //padding-left: 15px;
}

.r3d-qualityline:hover {
    background-color: lightgrey;
}

.r3d-editline:nth-child(odd)  {
    background-color: $color-r3d-lightgray;
}

.r3d-editline:nth-child(even)  {

}

.r3d-editline:hover {
    background-color: lightgrey;
}

.r3d-qualitygear {
    padding-right: 18px;
}

.r3d-repositoryshortinfo {
    display: inline-block;
}

.r3d-repositoryaction {
    text-align: right;
}

.r3d-repositoryaction a{
    margin-right: 10px;
}

.r3d-repositoryaction a:last-child{
    margin-right: 0;
}

.r3d-btn-add-repository .glyphicon-plus{
    color: $color-r3d-green
}

.r3d-btn-add-entity .glyphicon-plus{
    color: $color-r3d-green
}

.r3d-btn-del-entity .glyphicon-minus{
    color: $color-r3d-red
}

#r3d-editor {

}

#r3d-editor #r3d-preview {
    background: none;
    border: none;
}

#r3d-editor #controls {
    position: fixed;
    bottom: 0px;
    background-color: white;
    border-top: lightgray solid 1px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

#r3d-editor #controls .form-group{
    margin-left: 10px;
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

#r3d-editor #controls .form-group .col-sm-2 {
    display: none;
}

.r3d-navigation {
    padding: 0;
    margin: 0;
    width: 100%;
    clear: both;
}

.r3d-pagecontent {
    margin-top: 2em;
    margin-bottom: 2em;
}

.r3d-staticpages h1 {
    font-weight: bold;
}

.r3d-staticpages h2 {
    font-weight: bold;
    font-size: initial;
}

.r3d-icon-row {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px dashed #DDDDDD;
}

.r3d-icon-row.last {
    padding-bottom: 0px;
    border-bottom: none;
}

.r3d-access-table th {
    font-weight: normal;
}

.r3d-contact-form .form-group{
    margin-left: 0px;
    margin-right: 0px;
}

.r3d-searchresult .r3d-repository-view {
    margin-top: 0px;
}

.r3d-searchresult .r3d-repository-view .subjects,
.r3d-searchresult .r3d-repository-view .content-types,
.r3d-searchresult .r3d-repository-view .country{
    color: #777;
    background-color: #EFEFEF;
}

/* Fix for long subjects links causing overflow */
.r3d-searchresult .r3d-repository-view li {
    max-width: 100%;
}
.r3d-searchresult .r3d-repository-view li a {
    vertical-align: text-bottom;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.r3d-searchsubjectlevel{
    display: block;
}

.r3d-searchsubjectlevel-1 {
    @extend .r3d-searchsubjectlevel;
    padding-left: 0;
}

.r3d-searchsubjectlevel-2 {
    @extend .r3d-searchsubjectlevel;
    padding-left: 1em;
}

.r3d-searchsubjectlevel-3 {
    @extend .r3d-searchsubjectlevel;
    padding-left: 2em;
}

.r3d-searchsubjectlevel-4 {
    @extend .r3d-searchsubjectlevel;
    padding-left: 3em;
}

.r3d-searchbar .input-group-btn {
    width: 100px !important;
}

.r3d-startpage-background body {
    background: url(../../public/images/startpage/hills.jpeg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.r3d-startpage-background, .r3d-startpage-background body {
    height: 100%;
    margin: 0;
}

.r3d-navbar {
    background-color: #f8f8f8;
    border-bottom: 1px solid #DDDDDD;
    -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
    transition: background .5s ease-in-out,padding .5s ease-in-out;
}

.r3d-navbar-transparent {
    background-color: transparent;
    border: none;
}

.r3d-bg-white {
    background: white;
}

.r3d-navbar-transparent .navbar-header {
    visibility: hidden;
}

.r3d-navbar-transparent .navbar-toggle {
    visibility: visible;
}

.r3d-html {
    height: 100%;   /* size set to keep footer at the bottom */
}

.r3d-body {
    margin:0;
    padding-top: 70px;      /* fixed header padding */
    min-height:100%;            /* size set to keep footer at the bottom */
    height: 100%;

}

#r3d-content{
    position: relative;
    min-height: 100%;
    padding-bottom: 16em;    /* footer padding */
}

#r3d-footer {
    /* keep footer at the bottom */
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 8em;
    /* normal styling */
    background: none repeat scroll 0 0 #F9F9F9;
    border-top: 1px solid #DDDDDD;
    color: #666666;
    font-size: smaller;
    line-height: 2em;
    padding: 2em;
}

.r3d-startpage-background #r3d-content {
    height: 100%;
}

.r3d-startpage-background #r3d-footer {
    position: static;
}

.r3d-startpage-search-container {
    min-height: 500px;
    height: 85%;
    /*height: 500px;*/
}

.r3d-startpage-news-container {
    border-top: #e7e7e7;
    padding-top: 30px;
    padding-bottom: 30px;
}

.r3d-news-icon{
    width: 96px;
}

.r3d-startpage-marketing-container {
    background: none repeat scroll 0 0 #F9F9F9;
    padding-top: 30px;
    padding-bottom: 30px;
}
.r3d-startpage-socialmedia-container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.r3d-startpage-socialmedia-container svg, .r3d-startpage-socialmedia-container span {
    height: 32px;
    fill: #337ab7
}

.r3d-startpage-socialmedia-container svg {
    margin-right: 15px;
}

#r3d-growthchart-notice {
    margin-top: 5px;
    color: #797979;
    font-size: smaller;
    text-align: center;
}

.r3d-startpage-search-form {
    background-color: white;
    box-shadow: 15px 15px 50px rgba(0,0,0, 0.3);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    position: relative;
    top: 30%;
    transform: translateY(-50%);
}

.r3d-startpage-search-form > img {
    margin-bottom: 20px;
    max-width: 100%;
}

.r3d-text-bold {
    font-weight: bold;
}

.r3d-text-small {
    font-size: small;
}

.r3d-team{
    text-align: center;
}

.r3d-team-institution {
    font-size: smaller;
    text-align: center
}

.r3d-team-name {
    margin-top: 1em;
}

@media (min-width: 1480px) {
    .r3d-staticpages .r3d-table-of-contents {
        position: fixed;
        top: 70px;
        left: 10px;
        background-color: white;
        width: 250px;
    }
}

.r3d-staticpages .r3d-table-of-contents ul {
    list-style-type: none;
}

.r3d-staticpages .r3d-table-of-contents ul li {
    margin-bottom: 10px;
}

.anchor {
    position: relative;
    top:-100px;
    visibility: hidden;
}

@media(max-width: 767px){
    .r3d-searchbar{
        width: 100%;
    }
}

@media(min-width: 768px) and (max-width: 1279px){
    .r3d-searchbar{
        width: 350px;
    }
}

@media(min-width: 1280px){
    .r3d-searchbar{
        width: 450px;
    }
}

#r3d-diff ins {
    background: #6ecc6e !important;
}

#r3d-diff del {
    background: #f36c6c !important;
}

.main-sidebar {
    padding-top: 0px;
}

.main-sidebar a {
    color: white;
}

#r3d-edit-header {
    margin-top: 0px;
}