/*
    Created on : Mar 3, 2015, 11:04:32 AM
    Author     : kbroul
*/

@import 'colors';

.container.r3d-repository-view {
    padding-top: 2em;
}

.r3d-repository-view h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bolder;
}

.r3d-repository-view .separator {
    height: 1px;
    width: 100%;
    clear: both;
    margin: 5px 0px;
    border-bottom: 1px dashed $color-r3d-lightgray;
}

.r3d-repository-view ul {
    margin-bottom: 0px;
    padding-left: 0px;
}

.r3d-repository-view li {
    list-style: none;
}

.r3d-repository-view li.no-line-break {
    display: inline-block;
}

.r3d-repository-view li.no-line-break {
    margin-bottom: 5px;
}

.r3d-repository-view .label.subjects {
    background-color: $color-r3d-orange;
}

.r3d-repository-view .label.content-types {
    background-color: $color-r3d-blue;
}

.r3d-repository-view .label.keywords {
    background-color: $color-r3d-blue;
}

.r3d-repository-view .label.country {
    background-color: $color-r3d-green;
}

.r3d-repository-view .tab-content {
    padding-top: 20px;
}

.r3d-repository-view #tab_institutions .content-block:nth-child(n+2) {
    margin-top: 25px;
}

.r3d-repository-view #tab_terms .content-block:nth-child(n+2),
.r3d-repository-view #tab_standards .content-block:nth-child(n+2) {
    margin-top: 15px;
}


.r3d-repository-view .col-sm-12 {
    padding-right: 0px;
}

.r3d-repository-view .nav-tabs a {
    font-weight: bolder;
}

.r3d-repository-view h1 {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 25px;
}
.r3d-repository-view h2 {
    margin-left: 15px;
    margin-bottom: 10px;
    margin-top: 25px;
    font-size: 16px;
    color: $color-r3d-gray;
}

.r3d-repository-view .row:first-child h2 {
    margin-top: 0px;
}

.r3d-repository-view .r3d-main-heading h1{
    margin-top: 5px;
}

.r3d-repository-view .r3d-main-heading span {
    color: $color-r3d-gray;
}

.r3d-repository-view .r3d-main-icons {
    text-align: right;
}