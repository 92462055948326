/*
    Created on : Apr 30, 2015, 2:21:17 PM
    Author     : sasidhar
*/

.r3d-breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  border-radius: 4px;
}

.r3d-breadcrumb > li {
  display: inline-block;
}

.r3d-breadcrumb > li + li:before {
  padding: 0 5px;
  color: #cccccc;
  content: "/\00a0";
}

.r3d-breadcrumb > .active {
  color: #999999;
}

